export const environment = {
  production: true,
  pro: false,
  iframe: false,
  apiBaseUrl: "https://api.akces.fr/api",
  baseUrl: "https://api.akces.fr",
  fbAppId: "259796547837759",
/*
  apiBaseUrl: "http://dev-api.akces.fr/api",
  baseUrl: "http://dev-api.akces.fr",
  fbAppId: "259796547837759"*/
};
