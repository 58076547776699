import { Injectable } from '@angular/core';
import {Helper} from '../../utils/helper.module';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class FoEventsService {

  constructor(private http: HttpClient) {

  }

  getPricingDeviseSymbole(devise) {
    if(devise.toLowerCase() == 'eur')
      return '€';
    else if(devise.toLowerCase() == 'usd')
      return '$';
  }

  updateUserLang(user): Promise<any> {
    return this.http.post(Helper.api('updateUserLang'),user)
      .toPromise()
      .then((response) => {
        //console.log(Promise.resolve(response.json()));
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }


  organisation(id): Promise<any> {
    return this.http.get(Helper.api('organisations/'+id))
      .toPromise()
      .then((response) => {
        //console.log(response.json());
       // console.log(Promise.resolve(response.json()));
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }


  unLock(objet: any): Promise<any> {
    return this.http.post(Helper.api('unlock'), objet)
      .toPromise()
      .then((response) => {
        //console.log(response.json());
        return Promise.resolve(response);
      })
      .catch(
        this.handleError
      );
  }


  getLocation(term: string):Promise<any> {
    return this.http.get('https://maps.google.com/maps/api/geocode/json?address=' + term +'&key=AIzaSyBOWus4r7K7tQeCND85-segcBkIic07yTI')
      .toPromise()
      .then((response) => Promise.resolve(response))
  .catch((error) => Promise.resolve(error.json()));
  }


  monthevent(date): Promise<any> {
   // console.log(Helper.api('monthevent'));
    return this.http.get(Helper.api('monthevent/'+date))
      .toPromise()
      .then((response) => {
        //console.log(response.json());
        //console.log(Promise.resolve(response.json()));
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  discoSliderEvent(date): Promise<any> {
    // console.log(Helper.api('disco-slider'));
    return this.http.get(Helper.api('disco-slider/'+date))
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }


  event(id): Promise<any> {
    return this.http.get(Helper.api('foevent/'+id))
      .toPromise()
      .then((response) => {
      //  console.log(response.json());
       // console.log(Promise.resolve(response.json()));
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  checkroomavailability(room): Promise<any> {
    return this.http.post(Helper.api('checkroomavailbility'), room)
      .toPromise()
      .then((response) => {
        //  console.log(response.json());
        // console.log(Promise.resolve(response.json()));
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  pricing(id): Promise<any> {
  return this.http.get(Helper.api('pricing/'+id))
    .toPromise()
    .then((response) => {
    /*  console.log(response.json());
      console.log(Promise.resolve(response.json()));*/
      return Promise.resolve(response);
    })
    .catch(this.handleError);
}

  galery(id): Promise<any> {
    return this.http.get(Helper.api('gallery/'+id))
      .toPromise()
      .then((response) => {
        /*console.log(response.json());
        console.log(Promise.resolve(response.json()));*/
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }


  date(): Promise<any> {
    return this.http.get(Helper.api('date'))
      .toPromise()
      .then((response) => {
     //   console.log(response.json());
     //   console.log(Promise.resolve(response.json()));
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }


  categories(name): Promise<any> {
    return this.http.get(Helper.api('categorie/'+name))
      .toPromise()
      .then((response) => {
     /*   console.log(response.json());
        console.log(Promise.resolve(response.json()));*/
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  pricingGroupList(event_id): Promise<any> {
    return this.http.get(Helper.api('pricingGroups/'+event_id))
      .toPromise()
      .then((response) => {
     /*   console.log(response.json());
        console.log(Promise.resolve(response.json()));*/
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }


  saveEventVisit(visit): Promise<any> {
    return this.http.post(Helper.api('saveEventVisit'), visit)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }



  checkEventCodePromo(event_code): Promise<any> {
    return this.http.post(Helper.api('checkEventCodePromo'), event_code)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }


  DefaultForm(){

    let  form = {};
    form['inputsOrder'] = [
      {
        label: 'Nom',
        responseType: 'Répose Courte',
        isDefault:true,
        isMandatory: 1,
        inputType:'text'
      },
      {
        label: 'Prénom',
        responseType: 'Répose Courte',
        isDefault:true,
        isMandatory: 1,
        inputType:'text'
      },
      {
        label: 'Email',
        responseType: 'Répose Courte',
        isDefault:true,
        isMandatory: 1,
        inputType:'email'
      }
    ];

    form['inputsTicket'] = [
      {
        label: 'Nom',
        responseType: 'Réponse Courte',
        isDefault:true,
        isMandatory: 1,
        inputType:'text'
      },
      {
        label: 'Prénom',
        responseType: 'Réponse Courte',
        isDefault:true,
        isMandatory: 1,
        inputType:'text'
      },
      {
        label: 'Email',
        responseType: 'Répose Courte',
        isDefault:true,
        isMandatory: 1,
        inputType:'email'
      }
    ];

    return form;
  }



  pays(): Promise<any> {
    return this.http.get('assets/plugins/countries.js')
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getIp(): Promise<any> {
    return this.http.post(Helper.api('getIp'),{})
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }

  getReegistrationRequests(id:any): Promise<any> {
    return this.http.get(Helper.api('fogetsubstractionrequest/'+id),)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }

  getHousingPricingsType(id:any): Promise<any> {
    return this.http.get(Helper.api('housingspricings/'+id),)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }


  getAkcesLinks($date): Promise<any> {
    return this.http.get(Helper.api('akceslinks/'+$date))
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }


  checkSubstractionEmail(toCheck:any): Promise<any> {
    return this.http.post(Helper.api('checkEmailValidation'),toCheck)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }

  ValidateAndPay(data:any): Promise<any> {
    return this.http.post(Helper.api('validateAndpay'),data)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }

  ValidateAndGenerateOrder(data:any): Promise<any> {
    return this.http.post(Helper.api('validateregistrationOrderpdf'),data)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }


  SaveRegistrationData(data:any): Promise<any> {
    return this.http.post(Helper.api('savecheckedregistrationdata'),data)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }

  getOrderDeadline(id:any): Promise<any> {
    return this.http.get(Helper.api('orderdeadline/'+id))
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }

  paydeadline(deadline): Promise<any> {
    return this.http.post(Helper.api('orderdeadline'),deadline)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Helper.handleError);
  }


  initfacebookPixel (pixel) {
    (function (f: any, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }; if (!f._fbq) f._fbq = n;
      n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    (window as any).fbq.disablePushState = true; //not recommended, but can be done

     // if(window.location.href)
        (window as any).fbq('init', pixel);

    (window as any).fbq('track', 'PageView');
  }


  // TODO: #MEDIUM repeated code
  // with ctach() use an arrow function in order to use the keyword this
  private handleError(error: any, redirectUrl?: string): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error.json());
  }



}
