import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { CustomFormsModule } from 'ng2-validation';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import {CommonModule, registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import {JwtModule} from "@auth0/angular-jwt";
import {BrowserModule, BrowserTransferStateModule, TransferState} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {translateBrowserLoaderFactory} from "./translate-browser.loader";
import {TransferHttpCacheModule} from "@nguniversal/common";
import {AppRoutingModule} from "./app-routing.module";
import {RouterModule} from "@angular/router";
import {AuthService} from "./core/auth/auth.service";

export function tokenGetter() {
    return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    // , MomentModule
  ],
  imports: [
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
    RouterModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
  /*  BsDropdownModule.forRoot(),*/
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [
          'localhost:8000',
          'api.akces.fr',
          'dev-api.akces.fr'],
        disallowedRoutes: [],
      },
    }),
    CustomFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'akces-frontend' }),
    NgbModule,
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  providers: [AuthService]
})
export class AppModule { }
registerLocaleData(localeFr, 'fr');
