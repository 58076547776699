import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

import { Router } from '@angular/router';

import { Helper } from '../../utils/helper.module';

import { environment } from '../../../environments/environment';

import { WINDOW, LOCAL_STORAGE } from '@ng-toolkit/universal';
import {FoEventsService} from "../../fo/event/foevents.service";
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  isLoggedIn: boolean = false;
  modePro: boolean;

  // store a default URL so we can redirect after logging in
  managersDestinationUrl: string = "/manage/managerevents";
  defaultDestinationUrl: string = "/";
  loginUrl: string = "/login/signin";
  destination: any = "/"; // => make a setRedirection method ?
 /* this.retrieve('isProSignupProcessgoin')? this.retrieve('isProSignupProcessgoin') : false;*/
  isProSignupProcessgoin: boolean = false;
  acountName: any = this.retrieve('acountName')? this.retrieve('acountName') : '';

  constructor(
    @Inject(WINDOW) private window:Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private router: Router,
    private eventservice:FoEventsService,
    public jwtHelper: JwtHelperService
  ) {
    console.log('auth construct');

    // this.tokenSubscription();
    this.modePro = environment.pro;
    if (this.modePro) this.defaultDestinationUrl = '/manage/events';
  }

  private data = new BehaviorSubject('default data');
  data$ = this.data.asObservable();

  changeData(data: string) {
    this.data.next(data)
  }

  retrieve(key) {
    if (isPlatformBrowser(this.platformId)) {
      try {
        let value = localStorage.getItem(key);
        return JSON.parse(value);
      } catch (e) {
        console.log("localStorage not available");
        return null;
      }
    }
  }

  store(key,value):Promise<any> {
    if (isPlatformBrowser(this.platformId)) {
      try {
        localStorage.setItem(key, JSON.stringify(value));
        return;
      } catch (e) {
        console.log("localStorage not available");
        return null;
      }
    }
  }

  login(email, password,isPreAccount?:any,destinationUrl?: any): Promise<any> {
    return this.http.post(Helper.api('users/signin'), {'email': email, 'password': password})
       .toPromise()
       .then(res => {
          this.storeToken(res);
          return Promise.resolve("end login");
        })
        .catch((err) => {
                return this.handleError(err)
        });
  }

  loginWithToken(token): Promise<any> {
        return this.http.get(Helper.api('checkUser/'+ token))
          .toPromise()
          .then(res => {
            if(res != 'failed') {
              this.storeToken(res);
              return Promise.resolve("end login");
            }
            else {
              return Promise.resolve(res);
            }

          })
          .catch((err) => {
            return this.handleError(err)
          });
  }

  UserHasNotOrganisation(user) {
    return user.organization_id == 2;
  }

  UserHasNotOrganisationBis() {
    const user = this.getUser();
    return  user.organization_id == 2;
  }

  signup(account: any): Promise<any> {
       return this.http.post(Helper.api('users/signup'), account)
         .toPromise()
         .then((response) => {
           if(response != 'login') {
             let user = response['user'];
             this.storeUser(user);
             return Promise.resolve(true);
           } else {
             return Promise.resolve(response);
           }
         })
         .catch((err) => {
           return this.handleError(err);
         });
  }

  loggedIn() {
    if (isPlatformBrowser(this.platformId) && localStorage != undefined) {
      return this.hasValidToken();
    } else {
      return false;
    }
  }

  logout(): void {
    this.isLoggedIn = false;
    if (isPlatformBrowser(this.platformId)) {
      // empty user and token from localStorage
      // if we want to keep some datas, use remove instead of clear
      try {
        localStorage.clear();
        this.redirectTo(this.loginUrl);
      } catch (e) {
        console.log("localStorage not available");
      }
    }
  }

  logoutWithoutRedirect(): void {
    this.isLoggedIn = false;
    if (isPlatformBrowser(this.platformId)) {
      try {
        localStorage.clear();
      } catch (e) {
        console.log("localStorage not available");
      }
    }
  }

  tokenNotExpired() {
    const token: string = this.getToken();
    return token != null && !this.jwtHelper.isTokenExpired(token);
  }

  hasValidToken(fromUrl?: any): any {
    if (this.jwtHelper.isTokenExpired()) {
      // prepare login and destination
      if (fromUrl) {
        // store wanted destination
        this.setDestination(fromUrl);
        // redirect to login before dest
        this.redirectTo(this.loginUrl);
      }
      // return in any case
      return false;
    }
    return true;
  }

  hasValidTokenWithoutRedirect(): any {
    return !this.jwtHelper.isTokenExpired();
  }

  private resetDestination(){
    console.log('resetDestination');
    this.destination = this.defaultDestinationUrl;
  }

  setDestination(redirectUrl: any){
    console.log('setDestination');
    this.destination = redirectUrl;
  }

  private redirectTo(destination?: any) {
    if(!destination) destination = this.destination;
    if (typeof destination === "string") {
      console.log("redirectTo", destination);
      this.router.navigate([destination]);
    } else {
      this.router.navigate([destination.url], {queryParams:destination.params});
    }
  }

  redirect() {
    this.redirectTo();
  }

  getUser() {
    if (isPlatformBrowser(this.platformId) && localStorage != undefined ) {
      try {
        return JSON.parse(localStorage.getItem('user'));
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  public storeUser(user: any) {
    if (isPlatformBrowser(this.platformId)) {
      try {
        localStorage.setItem('user', JSON.stringify(user));
      } catch (e) {
        console.log("localStorage not available");
        return null;
      }
    }
  }

  getToken() {
    if (isPlatformBrowser(this.platformId) && localStorage != undefined ) {
      try {
        return localStorage.getItem('access_token');
      } catch (e) {
        console.log("localStorage not available");
        return null;
      }
    } else {
      return null;
    }
  }

  private storeToken(token): void {
    if (isPlatformBrowser(this.platformId)) {
      try {
        localStorage.setItem('access_token', token.access_token);
        localStorage.setItem('user', JSON.stringify(token.user));
        let language = this.retrieve('curentLang');

        if(token.user.organisation && token.user.organisation.facebook_pixel_id)
          this.store('facebook_pixel_id',token.user.organisation.facebook_pixel_id);

        if(token.user.organisation && token.user.organisation.google_analytic_id)
          this.store('google_analytic_id',token.user.organisation.google_analytic_id);

        if(language) {
          token.user.lang = language;
          this.eventservice.updateUserLang(token.user);
        }
      } catch (e) {
        console.log("localStorage not available");
        return null;
      }
    }
  }

  private decodeToken(token) {
    if (isPlatformBrowser(this.platformId) && localStorage != undefined ) {
      if(!token) return false;
      try {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      } catch(error) {
        console.error ('bad token format')
        return false;
      }
    } else {
      return false;
    }
  }




  private handleError(error: any, redirectUrl?: string): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    console.error('error status', error.status);
    return Promise.reject(error.message || error.json() || {'code':error.status});
  }

}
